import { $myWsWithAuth } from "@/utils/request-ws";
import { $post, $postWithAuth, $get, $getWithAuth } from "../utils/request";
import {
  UserAPI,
  BaseResponse,
  PageAPIParams,
  UserRequest,
  BaseAPI,
  UserApiMarginLoanRepayReq,
} from "./api";
import user from "@/views/user/user.vue";

export const $getCurrentUser = async (parmas: any) => {
  const r: BaseResponse = await $get(UserAPI.GetCurrentUser, parmas);
  return r;
};
export const $getCaptchaGenerate = async (parmas: any) => {
  const r: BaseResponse = await $get(UserAPI.GetCaptchaGenerate, parmas);
  return r;
};
export const $getCaptchaImage = async (parmas: any) => {
  const r: BaseResponse = await $get(UserAPI.GetCaptchaImage, parmas);
  return r;
};

//注册接口
export const $register = async (params: UserRequest) => {
  const api =
    UserAPI.PostRegister +
    "?captcha_id=" +
    params.captcha_id +
    "&captcha_value=" +
    params.captcha_value;
  const r: BaseResponse = await $post(api, params);
  return r;
};

export const $checkUsername = async (parmas: any) => {
  const r: BaseResponse = await $get(UserAPI.GetCheckUsername, parmas);
  return r;
};

export const $getUserConfig = async (parmas: any) => {
  const r: BaseResponse = await $getWithAuth(UserAPI.GetUserConfig, parmas);
  return r;
};

export const $checkEmail = async (parmas: any) => {
  const r: BaseResponse = await $get(UserAPI.GetCheckEmail, parmas);
  return r;
};

export const $checkPhone = async (parmas: any) => {
  const r: BaseResponse = await $get(UserAPI.GetCheckPhone, parmas);
  return r;
};
export const $checkInviteCode = async (parmas: any) => {
  const r: BaseResponse = await $get(UserAPI.GetCheckInviteCode, parmas);
  return r;
};

export const $getEmail = async (parmas: any) => {
  const r: BaseResponse = await $get(UserAPI.GetEmail, parmas);
  return r;
};

export const $getForgetPasswordSendEmail = async (parmas: any) => {
  const api = UserAPI.GetForgetPasswordSendEmail + "?email=" + parmas.email;
  const r: BaseResponse = await $get(api, {});
  return r;
};

export const $getPhone = async (parmas: any) => {
  const r: BaseResponse = await $get(UserAPI.GetPhone, parmas);
  return r;
};
//登录接口
export const $login = async (parmas: UserRequest) => {
  const api =
    UserAPI.PostLogin +
    "?captcha_id=" +
    parmas.captcha_id +
    "&captcha_value=" +
    parmas.captcha_value;
  const r: BaseResponse = await $post(api, parmas);
  return r;
};

export const $outLogin = async () => {
  const r: BaseResponse = await $postWithAuth(UserAPI.PostOutLogin, {});
  return r;
};

export const $checkToken = async () => {
  const r: BaseResponse = await $postWithAuth(UserAPI.PostCheckToken, {});
  return r;
};

export const $postUserResetPassword = async (params: any) => {
  const r: BaseResponse = await $postWithAuth(
    UserAPI.PostUserResetPassword,
    params
  );
  return r;
};

export const $postUserResetPasswordByEmail = async (params: any) => {
  const r: BaseResponse = await $post(
    UserAPI.PostUserResetPasswordByEmail,
    params
  );

  return r;
};

export const $postUserConfigSave = async (params: any) => {
  const r: BaseResponse = await $postWithAuth(
    UserAPI.PostUserConfigSave,
    params
  );
  return r;
};

export const $getUserApiList = async (params: any) => {
  const r: BaseResponse = await $getWithAuth(UserAPI.GetUserApiList, params);
  return r;
};
export const $getUserApiAll = async (params: any) => {
  const r: BaseResponse = await $getWithAuth(UserAPI.GetUserApiAll, params);
  return r;
};
export const $postUserApiSave = async (params: any) => {
  const r: BaseResponse = await $postWithAuth(UserAPI.PostUserApiSave, params);
  return r;
};
export const $postUserApiSaveActive = async (params: any) => {
  const r: BaseResponse = await $postWithAuth(
    UserAPI.PostUserApiSaveActive,
    params
  );
  return r;
};
export const $postUserApiDelete = async (params: any) => {
  const r: BaseResponse = await $postWithAuth(
    UserAPI.PostUserApiDelete,
    params
  );
  return r;
};

export const $getUserApiGroupList = async (params: any) => {
  const r: BaseResponse = await $getWithAuth(
    UserAPI.GetUserApiGroupList,
    params
  );
  return r;
};

export const $postUserApiGroupSave = async (params: any) => {
  const r: BaseResponse = await $postWithAuth(
    UserAPI.PostUserApiGroupSave,
    params
  );
  return r;
};

export const $postUserApiGroupDelete = async (params: any) => {
  const r: BaseResponse = await $postWithAuth(
    UserAPI.PostUserApiGroupDelete,
    params
  );
  return r;
};

export const $getUserLoginLogList = async (params: any) => {
  const r: BaseResponse = await $getWithAuth(
    UserAPI.GetUserLoginLogList,
    params
  );
  return r;
};
export const $getUserApiPositionLeverage = async (params: any) => {
  const r: BaseResponse = await $getWithAuth(
    UserAPI.GetUserApiPositionLeverage,
    params
  );
  return r;
};

export const $getUserApiPositionList = async (params: any) => {
  const r: BaseResponse = await $getWithAuth(
    UserAPI.GetUserApiPositionList,
    params
  );
  return r;
};
export const $getUserApiOpenOrderList = async (params: any) => {
  const r: BaseResponse = await $getWithAuth(
    UserAPI.GetUserApiOpenOrderList,
    params
  );
  return r;
};
export const $getUserApiHistoryOrderList = async (params: any) => {
  const r: BaseResponse = await $getWithAuth(
    UserAPI.GetUserApiHistoryOrderList,
    params
  );
  return r;
};

export const $getUserOrderPositionList = async (params: any) => {
  const r: BaseResponse = await $getWithAuth(
    UserAPI.GetUserOrderPositionList,
    params
  );
  return r;
};
export const $getUserOrderPositionCloseList = async (params: any) => {
  const r: BaseResponse = await $getWithAuth(
    UserAPI.GetUserOrderPositionCloseList,
    params
  );
  return r;
};
export const $getUserApiBalanceCurrency = async (params: any) => {
  const r: BaseResponse = await $getWithAuth(
    UserAPI.GetUserApiBalanceCurrency,
    params
  );
  return r;
};
export const $getUserApiBalanceUnique = async (params: any) => {
  const r: BaseResponse = await $getWithAuth(
    UserAPI.GetUserApiBalanceUnique,
    params
  );
  return r;
};

export const $getUserApiBalanceList = async (params: any) => {
  const r: BaseResponse = await $getWithAuth(
    UserAPI.GetUserApiBalanceList,
    params
  );
  return r;
};
export const $getUserApiBalanceAll = async (params: any) => {
  const r: BaseResponse = await $getWithAuth(
    UserAPI.GetUserApiBalanceAll,
    params
  );
  return r;
};
export const $getUserApiBalanceAllow = async (params: any) => {
  const r: BaseResponse = await $getWithAuth(
    UserAPI.GetUserApiBalanceAllow,
    params
  );
  return r;
};

export const $getUserApiBalanceDataList = async (params: any) => {
  const r: BaseResponse = await $getWithAuth(
    UserAPI.GetUserApiBalanceDataList,
    params
  );
  return r;
};
export const $postUserApiTransfer = async (params: any) => {
  const r: BaseResponse = await $postWithAuth(
    UserAPI.PostUserApiTransfer,
    params
  );
  return r;
};
export const $postUserApiAssetTransfer = async (params: any) => {
  const r: BaseResponse = await $postWithAuth(
    UserAPI.PostUserApiAssetTransfer,
    params
  );
  return r;
};

export const $getUserTradeTemplateList = async (params: any) => {
  const r: BaseResponse = await $getWithAuth(
    UserAPI.GetUserTradeTemplateList,
    params
  );
  return r;
};
export const $postUserTradeTemplateSave = async (params: any) => {
  const r: BaseResponse = await $postWithAuth(
    UserAPI.PostUserTradeTemplateSave,
    params
  );
  return r;
};
export const $postUserTradeTemplateDelete = async (params: any) => {
  const r: BaseResponse = await $postWithAuth(
    UserAPI.PostUserTradeTemplateDelete,
    params
  );
  return r;
};

export const $getUserSymbolSubscribeList = async (params: any) => {
  const r: BaseResponse = await $getWithAuth(
    UserAPI.GetUserSymbolSubscribeList,
    params
  );
  return r;
};
export const $postUserSymbolSubscribeAdd = async (params: any) => {
  const r: BaseResponse = await $postWithAuth(
    UserAPI.PostUserSymbolSubscribeAdd,
    params
  );
  return r;
};
export const $postUserSymbolSubscribeRemove = async (params: any) => {
  const r: BaseResponse = await $postWithAuth(
    UserAPI.PostUserSymbolSubscribeRemove,
    params
  );
  return r;
};

export const $getUserApiWs = (
  params: any,
  onopen: () => void,
  onmessage: (evt: any) => void,
  onerror: (evt: any) => void,
  onclose: () => void
) => {
  return $myWsWithAuth(
    UserAPI.GetUserApiWs,
    params,
    onopen,
    onmessage,
    onerror,
    onclose
  );
};

export const $getUserApiMarginAccount = async (params: any) => {
  const r: BaseResponse = await $getWithAuth(
    UserAPI.GetUserApiMarginAccount,
    params
  );
  return r;
};

export const $getUserApiMarginIsolatedAccount = async (params: any) => {
  const r: BaseResponse = await $getWithAuth(
    UserAPI.GetUserApiMarginIsolatedAccount,
    params
  );
  return r;
};

export const $postUserApiMarginLoanRepay = async (params: any) => {
  const r: BaseResponse = await $postWithAuth(
    UserAPI.PostUserApiMarginLoanRepay,
    params
  );
  return r;
};
export const $postUserApiMarginTransfer = async (params: any) => {
  const r: BaseResponse = await $postWithAuth(
    UserAPI.PostUserApiMarginTransfer,
    params
  );
  return r;
};

export const $getUserApiMarginMaxBorrowable = async (params: any) => {
  const r: BaseResponse = await $getWithAuth(
    UserAPI.GetUserApiMarginMaxBorrowed,
    params
  );
  return r;
};

export const $getUserApiMarginMaxTransferable = async (params: any) => {
  const r: BaseResponse = await $getWithAuth(
    UserAPI.GetUserApiMarginMaxTransfer,
    params
  );
  return r;
};

export const $getUserApiMarginInterestHistory = async (params: any) => {
  const r: BaseResponse = await $getWithAuth(
    UserAPI.GetUserApiMarginInterestHistory,
    params
  );
  return r;
};

export const $getUserApiPositionSetting = async (params: any) => {
  const r: BaseResponse = await $getWithAuth(
    UserAPI.GetUserApiPositionSetting,
    params
  );
  return r;
};

export const $postUserApiPositionSettingUpdate = async (params: any) => {
  const r: BaseResponse = await $postWithAuth(
    UserAPI.PostUserApiPositionSettingUpdate,
    params
  );
  return r;
};

// GetUserApiLeverageAssetMode: "/user/api/leverage/assetMode",
// GetUserApiLeveragePositionMode: "/user/api/leverage/positionMode",
// PostUserApiLeverageAssetModeUpdate: "/user/api/leverage/assetMode/update",
// PostUserApiLeveragePositionModeUpdate:
//   "/user/api/leverage/positionMode/update",
// PostUserApiLeverageRatioUpdate: "/user/api/leverage/ratio/update",
// PostUserApiLeverageMarginTypeUpdate: "/user/api/leverage/marginType/update",

// export const $getUserApiLeverageAssetMode = async (params: any) => {
//   const r: BaseResponse = await $getWithAuth(
//     UserAPI.GetUserApiLeverageAssetMode,
//     params
//   );
//   return r;
// };

// export const $getUserApiLeveragePositionMode = async (params: any) => {
//   const r: BaseResponse = await $getWithAuth(
//     UserAPI.GetUserApiLeveragePositionMode,
//     params
//   );
//   return r;
// };

// export const $getUserApiLeveragePositionSetting = async (params: any) => {
//   const r: BaseResponse = await $getWithAuth(
//     UserAPI.GetUserApiLeveragePositionSetting,
//     params
//   );
//   return r;
// };

// export const $postUserApiLeverageAssetModeUpdate = async (params: any) => {
//   const r: BaseResponse = await $postWithAuth(
//     UserAPI.PostUserApiLeverageAssetModeUpdate,
//     params
//   );
//   return r;
// };

// export const $postUserApiLeveragePositionModeUpdate = async (params: any) => {
//   const r: BaseResponse = await $postWithAuth(
//     UserAPI.PostUserApiLeveragePositionModeUpdate,
//     params
//   );
//   return r;
// };

// export const $postUserApiLeverageRatioUpdate = async (params: any) => {
//   const r: BaseResponse = await $postWithAuth(
//     UserAPI.PostUserApiLeverageRatioUpdate,
//     params
//   );
//   return r;
// };

// export const $postUserApiLeverageMarginTypeUpdate = async (params: any) => {
//   const r: BaseResponse = await $postWithAuth(
//     UserAPI.PostUserApiLeverageMarginTypeUpdate,
//     params
//   );
//   return r;
// };

export const $getSelectObserverList = async (params: any) => {
  const r: BaseResponse = await $getWithAuth(
    UserAPI.GetSelectObserverList,
    params
  );
  return r;
};

export const $getObserverUserApiList = async (params: any) => {
  const r: BaseResponse = await $getWithAuth(
    UserAPI.GetObserverUserApiList,
    params
  );
  return r;
};

export const $getFinanceUserApiList = async (params: any) => {
  const r: BaseResponse = await $getWithAuth(
    UserAPI.GetFinanceUserApiList,
    params
  );
  return r;
};
